<template>
	<div class="app-container" style="background-color: #fff;">
		<div class="flex">
			<buttonPermissions :datas="'tagAdd'">
				<el-button
					type="primary"
					style="width:100px;margin-bottom: 20px;"
					@click="addTag"
				>新增标签</el-button>
			</buttonPermissions>
			<buttonPermissions :datas="'tagsDel'">
				<el-button
					type="danger"
					style="width:100px;margin-bottom: 20px;margin-left: 20px;"
					@click="deleteCate({id:0})"
				>批量删除</el-button>
			</buttonPermissions>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="specList"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				@selection-change="handleSelectionChange"
				v-loading="listLoading"
			>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column prop="tagsName" label="标签名称"></el-table-column>
				<el-table-column label="商品数量">
					<template slot-scope="scope">
						<div v-if="scope.row.goodsCount==0">{{scope.row.goodsCount}}</div>
						<div v-else style="color: #409EFF;cursor: pointer;" @click="tagDoorLink(scope.row)">{{scope.row.goodsCount}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="250">
					<template slot-scope="scop">
						<div style="display:flex;">
							<buttonPermissions :datas="'tagEdit'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="editChild(scop.row)"
								>编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'tagsDel'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="deleteCate({id:scop.row.id})"
								>删除</el-button>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-pagination
			v-if="Total"
			style="margin:20px 0;float:right;"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="currentPage"
			:page-sizes="[10, 20, 30, 40, 50]"
			:page-size="pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="Total"
		></el-pagination>

		<!-- 新增标签 弹框 -->
		<el-dialog :visible.sync="specVisable" width="30%" title="商品标签" @close="closeEdit('ruleForm')">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="标签名称:"
						prop="specName"
						:rules="[{required:true,message:'标签名称不能为空',trigger: 'blur',maxlength:5}]"
					>
						<el-input
							v-model="ruleForm.specName"
							placeholder="请输入标签名称"
							maxlength="5"
							clearable
							style="width:300px"
						>
						<template slot="append">{{ruleForm.specName.length}}/5</template>
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="closeEdit('ruleForm')">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveChange('ruleForm')">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	addTags,
	delTags,
	listTags,
	editTags
} from "../../api/goods.js"

export default {
	data () {
		return {
			specVisable: false,
			ruleForm: {
				specName: ''
			},
			loading: false,
			specList: [],
			currentPage: 1,
			pageSize: 20,
			Total: 0,
			tagId: 0,
			listLoading: false,
			selectList:[],//批量选中的数据
		}
	},
	created () {
		this.initData();
	},
	methods: {
		async initData () {
			try {
				this.listLoading = true;
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				};
				let res = await listTags(data);
				if (res.success) {
					this.specList = res.data.records;
					this.Total = res.data.total;
				}
			} catch (e) {
				//TODO handle the exception
				console.log(e);
			} finally {
				this.listLoading = false;
			}
		},
		
		async saveChange (ruleName) {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					try{
						let data = {
							tagsName:this.ruleForm.specName,
						}
						let res = null;
						if(this.tagId>0){
							data.id = this.tagId;
							res = await editTags(data);
						}else{
							res = await addTags(data);
						}
						var str = this.tagId>0?'编辑':'添加';
						if(res.success){
							this.$message({
								showClose: true,
								type: 'success',
								message: '商品标签'+str+'成功'
							});
							this.specVisable = false;
							this.$refs[ruleName].resetFields();
							this.initData();
						}else{
							this.$message({
								showClose: true,
								type: 'error',
								message:res.alertMsg ? res.alertMsg : '商品标签'+str+'失败'
							});
						}
						console.log("-------",res);
					} catch (e) {
						//TODO handle the exception
					}
				}
			});
		},
		closeEdit(ruleName){
			this.specVisable = false;
			this.$refs[ruleName].resetFields();
		},
		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.initData();
		},

		// 翻页
		handleCurrentChange (val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.initData();
		},
		addTag () {
			this.tagId = 0;
			this.ruleForm.specName = '';
			this.specVisable = true;
		},
		editChild (row) {
			this.tagId = row.id;
			this.ruleForm.specName = row.tagsName;
			this.specVisable = true
		},
		async deleteCate (obj) {
			var ids = [];
			if(obj.id==0){
				if(!this.selectList.length){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择要删除的商品标签'
					});
					return;
				}
				this.selectList.map(item=>{
					ids.push(item.id);
					return item;
				})
			}else{
				ids.push(obj.id);
			}
			try {
				this.$confirm('是否确认删除所选商品标签？', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let res = await delTags({ids:ids});
					if (res.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message:'商品标签删除成功'
						});
						this.initData();
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.alertMsg ? res.alertMsg : '操作失败'
						});
					}
				})

			} catch (e) {
				//TODO handle the exception
			}
		},
		handleSelectionChange (val) {
			this.selectList = val;
		},
		//标签跳转
		tagDoorLink(record) {
			let params = {
				id: record.id
			}
			this.publicToDetails(params,'/goods/goodsList',this);
		},
	}
}
</script>

<style lang="less">
.pruduct-info > img {
	width: 80px;
	height: 80px;
	background-color: #f08047;
}
.spec-title {
	height: 70px;
	display: flex;
	margin-left: 10px;
	font-size: 14px;
	flex-direction: column;
	justify-content: space-between;
}
.flex {
	display: flex;
	align-items: center;
}
</style>
